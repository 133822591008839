html body{
  font-size: 10px;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: dodgerblue;
}


.container__title {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100vw;
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  margin: 12px 48px 20px 48px;

  @media (max-width: 750px) {
  margin: 12px 48px 20px 48px;
}
}

.subtitle {
  font-weight: bold;
  font-size: 1.7rem;
  color: white;
  margin: 0 0 48px;
}

.Calendar__wrapper {
  width: 95%;
  height: 100%;
  max-height: 80%;
  display: flex;
  margin: 0 auto;
  border: solid 1px white;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  background: white;
  flex-direction: column;
}

.container__button {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 48px;
  top: 48px;

  @media (max-width: 750px) {
    top: 16px;
    right: 16px;
  }
}

.slider:hover {
  animation: scrollText 7s infinite;
  width: 100%;
}

@keyframes scrollText {
  from   { transform: translateX(0%); }
  to { transform: translateX(-100%); }
}